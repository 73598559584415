import React, {useContext, useState} from 'react';
import {setPopupDispatch, setTime} from "../../store/actions/checker";
import {connect} from "react-redux";
import {ButtonContext} from "../../context/ButtonContext";

const PopupLate = (props) => {
    const [popupLateText, setPopupLateText] = useState('');
    const [textRender, setTextRender] = useState('оправдания тебе нет. но так и быть, можешь попробовать, ПИШИ:');




    const btn = (popupLateText.trim().length <= 5);
    const buttonAnim = useContext(ButtonContext);
    const submitPopupLate = (e) => {
        e.preventDefault();
        if (!btn) {
            props.setTime('popupLate', props.email, popupLateText);
            sessionStorage.setItem('popup', 'done');
            localStorage.setItem('popup', 'done');
            props.setPopupDispatch('done');
            setPopupLateText('');
        } else {
            setTextRender('Хорошая попытка. Но все равно, придется оправдываться!')
        }

    };
    return (
        <div className="popups__popup late active">
            <div className="popups__content popups__content--late">
                <div className="title title--late">
                    опоздание<span className="title__red">!</span>
                </div>
                <div className="title_small">
                    {textRender}
                </div>
                <form className="form" onSubmit={(e) => submitPopupLate(e)}>
                    <div className="form__box">
                                <textarea
                                    name="text"
                                    placeholder="Ну, рассказывай..."
                                    value={popupLateText}
                                    onChange={(e) => setPopupLateText(e.target.value)}
                                ></textarea>
                        <label className="form__label--error">Ну напиши же что нибудь...</label>
                    </div>
                    <div className="form__box form__box--submit">
                        <button onMouseEnter={(e)=> buttonAnim(e)} type="submit" disabled={btn} className="button button--text">
                            <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg"
                                 preserveAspectRatio="none">
                                <polygon className="cls-1"
                                         points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                <polygon className="cls-2"
                                         points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                            </svg>
                            <span className="button__line button__line--s"></span>
                            <span className="button__text">ОТПРАВИТЬ</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
};



function mapStateToProps(state) {
    return {
        email: state.auth.email,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setTime: (item, email,value) => dispatch(setTime(item, email,value)),
        setPopupDispatch: (popup) => dispatch(setPopupDispatch(popup))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupLate);
