import React, {useContext, useEffect, useState} from 'react';
import SelectUsers from "../selectUsers/SelectUsers";
import {addTimeInDinner} from "../../store/actions/adminPanel";
import {connect} from "react-redux";
import {ButtonContext} from "../../context/ButtonContext";



const TabInDinner = (props) => {
    const [stateInDinner, setStateInDinner] = useState({
        date: props.startDate,
        inDinner: '',
        disable: true
    });


    const submitAddWorkTime = (email,date,inDinner) => {
        props.addTimeInDinner(email,date,inDinner);
        setStateInDinner({
            ...stateInDinner,
            inDinner: ''
        });
    };

    const funcToSetDinner = (e) => {
        let number = e.replace(/\D/, '').replace(/00/, '0')

        if (number > 60) {
            setStateInDinner({
                ...stateInDinner,
                inDinner: 60
            })
        } else if (number < 0) {
            setStateInDinner({
                ...stateInDinner,
                inDinner: 0
            })
        }else if (number === '-') {
            setStateInDinner({
                ...stateInDinner,
                inDinner: 0
            })
        } else {
            setStateInDinner({
                ...stateInDinner,
                inDinner: number
            })
        }
    }
useEffect(() => {
    if (!isNaN(stateInDinner.inDinner) && (stateInDinner.inDinner)) {
        setStateInDinner({
            ...stateInDinner,
            disable: false
        })
    }

},[stateInDinner.inDinner]);

    const buttonAnim = useContext(ButtonContext);


    return (
        <div className="form_admin form_admin_lunch">
            <div className="form_admin__block">
                <div className="form_admin__left">
                    <div className="form_admin__box">
                        <SelectUsers all={false}/>
                    </div>
                    <div className="form_admin__box">
                        <input onChange={(e) => setStateInDinner({...stateInDinner, date: new Date(e.target.value) })}  type="date" data-name="date"/>
                        <div className="form_admin__date">
                            <span>{stateInDinner.date.toLocaleDateString()}</span>
                        </div>
                    </div>
                    <div className="form_admin__box">
                        <input placeholder={'--'} value={stateInDinner.inDinner} onChange={(e) => funcToSetDinner(e.target.value)} type="text"/>
                        <div className="form_admin__date">
                            {/*<span>{stateInDinner.inDinner}</span>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="form_admin__block">
                <div className="form_admin__box form_admin__box--submit">
                    <button onMouseEnter={(e)=> buttonAnim(e)}  disabled={stateInDinner.disable} onClick={() => submitAddWorkTime(props.filterName,stateInDinner.date, stateInDinner.inDinner )} type="button" className="button button--red button--red_bg">
                        <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg"
                             preserveAspectRatio="none">
                            <polygon className="cls-1"
                                     points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                            <polygon className="cls-2"
                                     points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                        </svg>
                        <span className="button__line button__line--s"></span>
                        <span className="button__text">РАБОТАЛ В ОБЕД</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        startDate: state.adminPanel.startDate,
        filterName: state.adminPanel.filterName,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        addTimeInDinner: (email,date,inDinner) => dispatch(addTimeInDinner(email,date,inDinner)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TabInDinner);
// export default Tabs;