import React, {useState} from 'react';




export const Input = (props) => {
    const  [type, setTypeText] = useState('true');
    const toggleType = () => {
        setTypeText(!type);
    };

    const cls = (props.mbLong) ? "form__box" : "form__box form__box--pd";
    const inputType = props.type || 'text';
    const pass = (inputType === 'password') ? <label onClick={toggleType} className="form__label--pass"></label> : null;
    const isInvalid = (props.isInvalid) ? `${cls} item-error` : cls;

    return (

        <>
            <div className={isInvalid}>
                <input name={props.name}
                       type={type ? inputType : 'text'}
                       required
                       placeholder={props.placeholder}
                       onChange={props.onChange}
                       onBlur={props.onBlur}
                       value={props.value}
                />
                {pass}
                <label htmlFor={props.id} className="form__label--error">{props.errorMessage}</label>
            </div>
        </>
    )
}