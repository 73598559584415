import React, {useEffect, useState} from 'react'
import './switch.css'
import {connect} from "react-redux";
import {changeTheme} from "../../../store/actions/auth";


const Switch = (props) => {

    return (
        <div className={'switch ' + props.theme}>
            <div onClick={() => props.changeTheme(props.email,'dark')} className={'switch__item switch__item--dark'}>
                <img src="./public/img/dark.svg" alt=""/>
            </div>
            <div onClick={() => props.changeTheme(props.email,'light')} className={'switch__item switch__item--light'}>
                <img src="./public/img/light.svg" alt=""/>
            </div>
        </div>
    )
}
// export default Switch;

function mapStateToProps(state) {
    return {
        theme: state.auth.theme,
        email: state.auth.email
    }
}
function mapDispatchToProps(dispatch) {
    return {
        changeTheme: (email,theme) => dispatch(changeTheme(email,theme))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Switch)