import React, {useContext, useState} from 'react';

import {addWorkingTime, setUserStartEndDate} from "../../store/actions/adminPanel";
import {connect} from "react-redux";

import TabShow from "./TabShow";
import TabAddWorkTime from "./TabAddWorkTime";
import TabInDinner from "./TabInDinner";
import Search from "./Search";
import {RateContext} from "../../context/RateContext";


const Tabs = () => {
    const [tabsID, setTabsID] = useState(1);
    const allAdminTables = useContext(RateContext);
    let usersName = [];
    let usersNameObj = {};
    allAdminTables.forEach((item) => {
        usersName.push(item.name)
        usersNameObj[item.email] = item.name
    })

    let tabsContent;
    switch (tabsID) {
        case 1:
            tabsContent = (
                <TabShow/>
            );
            break;
        case 2:
            tabsContent = (
                <TabAddWorkTime/>
            );
            break;
        case 3:
            tabsContent = (
                <TabInDinner/>
            );
            break;
        default:
            break;
    }

    return (
        <div className="tabs">

            <div className="tabs__container">
                <div className="tabs__btns">
                    <div onClick={() => setTabsID(1) }  className={(tabsID === 1) ? "tabs__button active": "tabs__button"}>
                        <span>ПОКАЗАТЬ  сколько отработаНО</span>
                    </div>
                    <div onClick={() => setTabsID(2)} className={(tabsID === 2) ? "tabs__button active": "tabs__button"}>
                        <span>ДОБАВИТЬ РАБОЧЕЕ ВРЕМЯ</span>
                    </div>
                    <div onClick={() => setTabsID(3)} className={(tabsID === 3) ? "tabs__button active": "tabs__button"}>
                        <span>ДОБАВИТЬ ВРЕМЯ В ОБЕД</span>
                    </div>
                </div>

                <div className="tabs__content">
                    <div className="tabs__block active" >
                        {tabsContent}
                    </div>

                </div>
            </div>




            <Search
                usersNameObj={usersNameObj}
                options={usersName}
            />
        </div>
    )
};

function mapStateToProps(state) {
    return {
        startDate: state.adminPanel.startDate,
        endDate: state.adminPanel.endDate,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUserStartEndDate: (start,end,filterName) => dispatch(setUserStartEndDate(start,end,filterName)),
        addWorkingTime: (email,date,timeStart,timeEnd, fromHome) => dispatch(addWorkingTime(email,date,timeStart,timeEnd, fromHome)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
// export default Tabs;