import React, {useEffect, useState} from 'react';
import moment from 'moment';

const TableItem = (props) => {
    const [data, setData] = useState(0);

    const getDate = (string) => new Date(0, 0, 0 , string.split(':')[0], string.split(':')[1]); //получение даты из строки (подставляются часы и минуты


 let different, timeToEnd, all, result, customDinner = 0, customPause = 0;

    // console.log(props.id.replaceAll(' ', ''))
    // console.log(new Date().toLocaleDateString('fr-CA') === props.id.replaceAll(' ', ''))

    if ((props.endTime !== '--:--') && props.endTime) {
        if (props.inDinner) customDinner = props.inDinner;
        if (props.resume) customPause = moment.duration(props.resume, "milliseconds");
        different = (getDate(props.endTime) - getDate(props.startTime) + (customDinner * 60000) - customPause);
        timeToEnd = (9*60*60000) - different;
        all = moment.duration(Math.abs(different), 'milliseconds').format("hh:mm", {trim: false});
        result = moment.duration(Math.abs(timeToEnd), 'milliseconds').format("hh:mm", {trim: false});

    } else if (new Date().toLocaleDateString('fr-CA') === props.id.replaceAll(' ', '')){
        timeToEnd = '--:--';
        different = 9*60*60000;
    }  else {
        timeToEnd = '--:--';
        different = 0;
    }



    let text, className;
    if (timeToEnd > 0) {
         className = 'color--red';
         text = 'НедоработКА:';
    } else if (timeToEnd < 0) {
         className = 'color--green';
         text = 'ПереработКА:';
    } else if (new Date().toLocaleDateString('fr-CA') === props.id.replaceAll(' ', '')) {
        className = '';
        text = '';
    } else if (timeToEnd === '--:--') {
        className = 'color--red';
        text = 'НедоработКА: 09:00';
    }  else {
         className = '';
         text = 'p–p–p–perfect:';
    }





    useEffect(() => {
        setData(different);
    },[]);


    useEffect(() => {
        props.plusWorkedAll(-data)
        props.plusWorkedAll(different)
        setData(different);
    },[props.endTime, props.startTime, props.inDinner]);



        return (
        <>
            <div className="table__row">
                <div className="table__cell table__cell--date">
                    <span>{props.id}</span>
                </div>
                <div className="table__cell table__cell--come">
                    <span>{props.startTime}</span>
                </div>
                <div className="table__cell table__cell--out">
                    <span>{(props.endTime !== '--:--') ? props.endTime : '--:--'}</span>

                </div>
                <div className={"table__cell table__cell--all " + className}>
                    <div className="table__cell_left">
                        <span>{all}</span>
                    </div>


                    {(props.inDinner !== 0 && props.inDinner) ?  (
                        <div className="table__cell_right table__cell_right--dinner ">


                            <div className="table__comment active">
                                <div className="table__comment_img table__comment_img--dinner">
                                    <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g >
                                            <path
                                                d="M9.79051 0.915633L20.0849 11.21C20.3412 11.4663 20.3412 11.8824 20.0849 12.1387L18.2919 13.9317C18.0357 14.188 17.6195 14.188 17.3633 13.9317L7.06885 3.63729C6.81257 3.38101 6.81257 2.96489 7.06885 2.70861L8.86183 0.915633C9.11811 0.659353 9.53423 0.659353 9.79051 0.915633Z"
                                                fill="#00CF2C"/>
                                            <path
                                                  d="M6.8632 9.90669C7.30608 9.46381 8.02522 9.46369 8.4681 9.90657C8.91098 10.3494 8.91098 11.0687 8.4681 11.5116C8.02522 11.9545 7.30596 11.9545 6.86308 11.5116C6.4202 11.0687 6.42032 10.3496 6.8632 9.90669ZM5.93156 14.1377C6.37444 13.6948 7.0937 13.6948 7.53658 14.1377C7.97946 14.5805 7.97934 15.2997 7.53646 15.7426C7.09358 16.1854 6.37444 16.1856 5.93156 15.7427C5.48868 15.2998 5.48868 14.5805 5.93156 14.1377ZM7.12207 4.66675L4.9004 10.8528L2.50421 17.5251C2.20365 18.3619 2.63901 18.7972 3.47581 18.4967L10.1481 16.1005L16.3341 13.8788L7.12207 4.66675ZM10.6285 11.7192C11.0714 11.2763 11.7904 11.2763 12.2333 11.7192C12.6761 12.162 12.6763 12.8812 12.2334 13.3241C11.7905 13.7669 11.0712 13.7669 10.6284 13.3241C10.1855 12.8812 10.1856 12.162 10.6285 11.7192Z"
                                                  fill="#00CF2C"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="21" height="21" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="table__comment_text table__comment_text--dinner">
                                    {props.inDinner} минут в обед
                                </div>
                            </div>
                        </div>


                    ) : null}

                    {(props.resume) ?  (
                        <div className="table__cell_right table__cell_right--dinner table__cell_right--pauseCustom">


                            <div className="table__comment active">
                                <div className="table__comment_img table__comment_img--dinner">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 2C5.81158 2 2 5.81158 2 10.5C2 15.1884 5.81158 19 10.5 19C15.1884 19 19 15.1884 19 10.5C19 5.81158 15.1884 2 10.5 2ZM9.42632 13.0411C9.42632 13.7747 8.83579 14.3653 8.10211 14.3653C7.36842 14.3653 6.7779 13.7747 6.7779 13.0411V7.95895C6.7779 7.22526 7.36842 6.63474 8.10211 6.63474C8.83579 6.63474 9.42632 7.22526 9.42632 7.95895V13.0411ZM14.2221 13.0411C14.2221 13.7747 13.6316 14.3653 12.8979 14.3653C12.1642 14.3653 11.5737 13.7747 11.5737 13.0411V7.95895C11.5737 7.22526 12.1642 6.63474 12.8979 6.63474C13.6316 6.63474 14.2221 7.22526 14.2221 7.95895V13.0411Z" fill="white"/>
                                    </svg>

                                </div>
                                <div className="table__comment_text table__comment_text--dinner">
                                    Пауза в работе: {moment.duration(props.resume).format('h\ч m\м')}
                                </div>
                            </div>
                        </div>


                    ) : null}
                </div>
                <div className={"table__cell table__cell--result " + className}><span>{text} {result}</span></div>
            </div>
        </>
    )

}




export default TableItem;