import React, {useContext, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {autoGetTime, setTime} from "../../store/actions/checker";
import Table from "../Table/Table";
import Popups from "../popups/Popups";
import {ButtonContext} from "../../context/ButtonContext";
// import firebase from "firebase";

// import firebase from 'firebase/app';
// import 'firebase/auth';        // for authentication
// import 'firebase/storage';     // for storage
// import 'firebase/database';    // for realtime database
// import 'firebase/firestore';




const MainPage = (props) => {
    const [valueInDinner, setValueInDinner] = useState(props.inDinner);

    const funcToSetDinner = (e) => {
        let number = e.replace(/\D/, '').replace(/00/, '0')
        if (number > 60) {
            setValueInDinner(60)
        } else if (number < 0) {
            setValueInDinner(0)
        }else if (number === '-') {
            setValueInDinner(0)
        } else {
            setValueInDinner(number)
        }
    }

    useEffect(() => {
        setValueInDinner(props.inDinner)
    },[props.inDinner]);


    const [classForDinner, setClassForDinner] = useState(false);
    const classInDinner = (classForDinner || props.inDinner > 0) ? "time__box time__box--form active" : "time__box time__box--form";
    const classInDinnerButtonAfter = (classForDinner || props.inDinner > 0) ? "button button--red button--red_bg" : "button button--black_bg";
    const classInDinnerButtonBefore = (classForDinner || props.inDinner > 0) ? "button  button--red button--black_bg button--add_time disable" : "button  button--red button--black_bg button--add_time";

   useEffect(() => {
       props.autoGetTime(props.email);
   },[props.startTime,props.endTime,props.inDinner]);


    const setTimeOut = () => {
        props.setTime('endTime', props.email);

    };
    const buttonAnim = useContext(ButtonContext);


    return (
        <>
            <div className="main">

                <div className="main__block">
                    <div className="title title--restore">
                        Отмечалочка<span className="title__red" >.</span>
                    </div>

                    <div className="time">
                        <div className="time__block">
                            <div className="form_come time__container">
                                <div className="time__box time__box--come">
                                    <span>{props.startTime}</span>
                                </div>
                                <button
                                    type="button"
                                    onMouseEnter={(e)=> buttonAnim(e)}
                                    onClick={() => props.setTime('startTime', props.email)}
                                    disabled={(props.startTime === '--:--') ? false :true}
                                    className="button button--red button--red_bg button--come">
                                    <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg">
                                        <polygon className="cls-1"
                                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                        <polygon className="cls-2"
                                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                    </svg>
                                    <span className="button__line button__line--s"></span>
                                    <span className="button__text">Я на работе</span>
                                </button>
                            </div>
                            <div className="form_leave time__container">
                                <div className="time__box time__box--leave">
                                    <span>{props.endTime}</span>
                                </div>
                                <button
                                    type='button'
                                    onMouseEnter={(e)=> buttonAnim(e)}
                                    onClick={() => setTimeOut()}
                                    disabled={(props.endTime !== '--:--' || props.startTime === '--:--') ? true : false}
                                    className="button button--white button--leave">
                                    <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg">
                                        <polygon className="cls-1"
                                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                        <polygon className="cls-2"
                                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                    </svg>
                                    <span className="button__text">Я домой</span>
                                </button>
                            </div>
                            <div className="time__container">
                                <div className={classInDinner}>
                                    <div className="time__title">
                                        Введи количество отработанных минут
                                    </div>
                                    <div className="form_time">
                                        <div className="form_time__box">
                                            <input type="text"
                                                   onChange={(e) => funcToSetDinner(e.target.value)}
                                                   value={valueInDinner}
                                                   data-name="timeM"
                                                   data-valid="false"
                                                   className={'inputInDinner'}
                                            />
                                        </div>
                                        <div className="form_time__box form_time__box--submit">
                                            <button
                                                onClick={
                                                    () => props.setTime('inDinner', props.email, valueInDinner)
                                                }
                                                type="button"
                                                onMouseEnter={(e)=> buttonAnim(e)}
                                                className={classInDinnerButtonAfter}>
                                                <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg"
                                                     preserveAspectRatio="none">
                                                    <polygon className="cls-1"
                                                             points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                                    <polygon className="cls-2"
                                                             points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                                </svg>
                                                <span className="button__text">ДОБАВИТЬ</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type='button'
                                    className={classInDinnerButtonBefore}
                                    onClick={() =>{setClassForDinner(true)}}
                                    onMouseEnter={(e)=> buttonAnim(e)}
                                >
                                    <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg">
                                        <polygon className="cls-1"
                                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                        <polygon className="cls-2"
                                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                    </svg>
                                    <span className="button__text">РАБОТАЮ В ОБЕД</span>
                                </button>
                            </div>
                            <div className="time__container">
                                <button
                                    type='button'
                                    className="button button--black_bg"
                                    disabled={(props.endTime === '--:--')}
                                    data-popup="late"
                                    onClick={() => props.setTime('resume', props.email)}
                                    onMouseEnter={(e)=> buttonAnim(e)}
                                >
                                    <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg">
                                        <polygon className="cls-1"
                                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                        <polygon className="cls-2"
                                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                    </svg>
                                    <span className="button__text ">ПРОДОЛЖИТЬ РАБОТУ</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <Table />
                </div>

            </div>

            <Popups/>
        </>
    )
};

function mapStateToProps(state) {
    return {
        startTime: state.checker.startTime,
        endTime: state.checker.endTime,
        inDinner: state.checker.inDinner,
        tablePerDate: state.checker.tablePerDate,
        email: state.auth.email,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setTime: (item, email,value) => dispatch(setTime(item, email,value)),
        autoGetTime: (email) => dispatch(autoGetTime(email))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MainPage);