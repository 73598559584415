import React, {useContext, useEffect, useState} from 'react';
import SelectUsers from "../selectUsers/SelectUsers";
import {addWorkingTime, setUserStartEndDate} from "../../store/actions/adminPanel";
import {connect} from "react-redux";
import {ButtonContext} from "../../context/ButtonContext";
import moment from "moment";


const TabAddWorkTime = (props) => {
    const [addWorkTime, setAddWorkTime] = useState({
        startDate: props.startDate,
        disable: true,
        startTime: '--:--',
        endTime: '--:--',
        fromHome: false
    });


    const getDate = (string) => new Date(0, 0, 0 , string.split(':')[0], string.split(':')[1]);
    useEffect(() => {
        const different = getDate(addWorkTime.endTime) - getDate(addWorkTime.startTime);


        if (!isNaN(different) && (different > 0)) {
            setAddWorkTime({
                ...addWorkTime,
                disable: false
            })
        } else {
            setAddWorkTime({
                ...addWorkTime,
                disable: true
            })
        }
    }, [addWorkTime.startTime, addWorkTime.endTime]);







    const onHandleFromHome = () => {
        setAddWorkTime({
            ...addWorkTime,
            fromHome: !addWorkTime.fromHome});
    };


    const submitAddWorkTime = (email,date,timeStart,timeEnd, fromHome) => {

        // console.log(date)
        props.addWorkingTime(email,date,timeStart,timeEnd, fromHome);

        setAddWorkTime({
            ...addWorkTime,
            startTime: '--:--',
            endTime: '--:--',
            disable: true,
            fromHome: false
        });

    };


    const buttonAnim = useContext(ButtonContext);



    return (
        <div className="form_admin form_admin_add_time">
            <div className="form_admin__block">
                <div className="form_admin__left">
                    <div className="form_admin__box">
                        <SelectUsers all={false}/>
                    </div>
                    <div className="form_admin__box">
                        <input onChange={(e) => setAddWorkTime({...addWorkTime, startDate: new Date(e.target.value) })}  type="date" data-valid="false"/>
                        <div className="form_admin__date">
                            <span>{addWorkTime.startDate.toLocaleDateString()}</span>
                        </div>
                    </div>
                    <div className="form_admin__box">
                        <input onChange={(e) => setAddWorkTime({...addWorkTime, startTime: e.target.value })} type="time" data-name="time"
                               data-valid="false"/>
                        <div className="form_admin__date">
                            <span>{addWorkTime.startTime}</span>
                        </div>
                    </div>
                    <div className="form_admin__box">
                        <input onChange={(e) => setAddWorkTime({...addWorkTime, endTime: e.target.value })} type="time" data-name="time"
                               data-valid="false"/>
                        <div className="form_admin__date">
                            <span>{addWorkTime.endTime}</span>
                        </div>
                    </div>
                    <div className="form_admin__box form_admin__box--check">
                        <div className="form_admin__box--rule">
                            <input onChange={() => onHandleFromHome()} checked={addWorkTime.fromHome} id="check_desk1" type="checkbox"/>
                            <label htmlFor="check_desk1">Работал из дома</label>
                            <label className="form__label--radio"
                                   htmlFor="check_desk1"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form_admin__block">
                <div className="form_admin__box form_admin__box--submit">
                    <button
                        onMouseEnter={(e)=> buttonAnim(e)} disabled={addWorkTime.disable}
                        type="button"
                        onClick={() => {

                            submitAddWorkTime(
                                props.filterName,
                                addWorkTime.startDate,
                                addWorkTime.startTime,
                                addWorkTime.endTime,
                                addWorkTime.fromHome,


                            )

                        }}
                        className="button button--red button--red_bg">

                        <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg"
                             preserveAspectRatio="none">
                            <polygon className="cls-1"
                                     points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                            <polygon className="cls-2"
                                     points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                        </svg>
                        <span className="button__line button__line--s"></span>
                        <span className="button__text">РАБОТАЛ</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        startDate: state.adminPanel.startDate,
        filterName: state.adminPanel.filterName
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUserStartEndDate: (start,end,filterName) => dispatch(setUserStartEndDate(start,end,filterName)),
        addWorkingTime: (email,date,timeStart,timeEnd, fromHome) => dispatch(addWorkingTime(email,date,timeStart,timeEnd, fromHome )),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TabAddWorkTime);
// export default Tabs;