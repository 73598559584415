import {SET_END_TIME, SET_IN_DINNER, SET_POPUP, SET_START_TIME, SET_THEME} from "../actions/actionTypes";



const initialState = {

    startTime: '--:--',
    endTime: '--:--',
    inDinner: 0,
    popup: localStorage.getItem('popup') || '',

};

export default function checkerReducer(state = initialState, action) {
    switch (action.type) {
        case SET_START_TIME:
            return {
                ...state,
                startTime: action.startTime,
            };
        case SET_END_TIME:
            return {
                ...state,
                endTime: action.endTime,
            };
        case SET_IN_DINNER:
            return {
                ...state,
                inDinner: action.inDinner,
            };

        case SET_POPUP:
            return {
                ...state,
                popup: action.popup,
            };

        default:
            return state;
    }
}