import React, { useEffect, useState} from 'react'
import {BrowserRouter as Router,Redirect, Route,Switch} from 'react-router-dom'

// ---  Pages  -----
import MainPage from './components/pages/MainPage'
import LoginPage from './components/pages/LoginPage'
import AdminPage from './components/pages/AdminPage'
import DonePage from './components/pages/DonePage'
import Registration from './components/pages/RegistrationPage'
import RestorePage from './components/pages/RestorePage'

// ---  Components ----
import Header from './components/header/Header'
import Logout from "./components/Logout/Logout";

// ---  dif ----
import {connect} from "react-redux";


// actions
import {autoLogin} from "./store/actions/auth";
import './App.css'


function App (props) {
    const [checkAuth, setCheckAuth] = useState('wait')





    useEffect(() => {
        setCheckAuth(props.isAuthenticated)
    },[props.isAuthenticated])
    useEffect(() => {
        props.autoLogin();

    });


    let switchRoutes;
    if (props.isAuthenticated && props.isAdmin) {
        switchRoutes = (
            <Switch>
                <Route path={"/"} exact component={MainPage} />
                <Route path={"/admin"} component={AdminPage} />
                <Route path={"/done"} component={DonePage} />
                <Route path={"/logout"} component={Logout} />
                <Redirect to={'/'}/>
            </Switch>
        )
    }else if (props.isAuthenticated) {
        switchRoutes = (
            <Switch>
                <Route path={"/"} exact component={MainPage} />
                <Route path={"/logout"} component={Logout} />
                <Redirect to={'/'}/>
            </Switch>
        )
    } else if (!checkAuth) {
        switchRoutes = (
            <Switch>
                <Route path={"/login"} component={LoginPage} />
                <Route path={"/registration"} component={Registration} />
                <Route path={"/restore"} component={RestorePage} />
                <Route path={"/logout"} component={Logout} />
                <Route path={"/done"} component={DonePage} />
                <Redirect to={'/login'}/>
            </Switch>
        );
    }

    return (


        <Router path="/">

            <div className="App">
                {(!!props.redirect)? <Redirect to={props.redirect}/>: null}
                <div className={'body-wrap' + ' body-wrap--' + props.theme} style={{minHeight: '100vh'}}>
                    <canvas id="playground"></canvas>
                    <Header/>
                    {switchRoutes}
                </div>
            </div>
        </Router>


    )
}


function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth.token,
        isAdmin: state.auth.isAdmin,
        redirect: state.auth.redirect,
        nameAuth: state.auth.name,
        theme: state.auth.theme
    }
}
function mapDispatchToProps(dispatch) {
    return {
        autoLogin: () => dispatch(autoLogin())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(App)
