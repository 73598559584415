import React from 'react'
import {Input} from '../input/Input'
import {ButtonForm} from '../buttonForm/ButtonForm'
import {Formik, Field} from "formik"
import * as yup from "yup"
import {connect} from 'react-redux'
import {auth} from "../../store/actions/auth"



const Login = (props) => {

    const validationSchema = yup.object().shape({
        email: yup.string().email('Укажите правильный email').required('Обязательное'),
        password: yup.string().typeError('Должно быть строкой').required('Обязательное'),

    });
    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                checked: false
            }}
            validateOnBlur
            validateOnChange
            onSubmit={(values) => {console.log(values)}}
            validationSchema={validationSchema}
        >
            {({values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty}) => (
                <form onSubmit={handleSubmit} className="form" id="form">
                    <Input
                        type={'email'}
                        name={'email'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder={'Введи почту @clickable.agency'}
                        errorMessage={errors.email}
                        isInvalid={touched.email && errors.email}
                    />
                    <Input
                        type={'password'}
                        name={'password'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder='Пароль'
                        errorMessage={errors.password}
                        isInvalid={touched.password && errors.password}
                    />
                    <div className="form__box form__box--check">
                        <div className="form__box--rule">
                            <Field
                                id={'checked'}
                                type="checkbox"
                                name="checked"
                            />
                            <label className="form__label--radio" htmlFor="checked"></label>
                            <label htmlFor="checked">Запомните меня таким</label>
                        </div>
                    </div>
                    <ButtonForm
                        disabled={!(isValid && dirty)}
                        text='ЗАЛОГИНИТЬСЯ'
                        click={() =>props.auth('login', values.email,values.password,values.checked)}
                    />
                </form>
            )}
        </Formik>
    )
};

function mapDispatchToProps(dispatch){
    return {
        auth: (fetchType, email, password,checked ) => dispatch(auth(fetchType, email, password,checked ))
    }
}


export default connect(null, mapDispatchToProps)(Login)