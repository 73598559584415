import React, {useContext, useEffect} from 'react';
import {RateContext} from "../../context/RateContext";
import {handleSetFilterName} from "../../store/actions/adminPanel";
import {connect} from "react-redux";



const SelectUsers = (props) => {

    const allAdminTables = useContext(RateContext);
    useEffect(()=>{
        const selectFilterName = document.getElementById('selectFilterName');
        props.handleSetFilterName(selectFilterName.value);
    },[]);

    let usersName = allAdminTables
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => <option key={item.id} value={item.email} >{item.name}</option>);
    const allUsers = (props.all) ? <option value={'all'}>Показать всех</option> : null;


    return (
        <select id='selectFilterName' value={props.filterName} onChange={(e)=> {props.handleSetFilterName(e.target.value)}}>
            {allUsers}
            {usersName}
        </select>
    )
};

function mapStateToProps(state) {
    return {
        filterName: state.adminPanel.filterName
    }
}

function mapDispatchToProps(dispatch) {
    return {
        handleSetFilterName: (filterName) => dispatch(handleSetFilterName(filterName)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectUsers);

