import React from 'react';

import {Input} from '../input/Input'
import {ButtonForm} from '../buttonForm/ButtonForm'
import {Formik} from "formik";
import * as yup from 'yup';
import {connect} from "react-redux";
import {auth} from "../../store/actions/auth";


const Registration = (props) => {

    const validationsSchema = yup.object().shape({
        name: yup.string().typeError('Должно быть строкой').matches('[A-Za-zА-Яа-яЁё] [A-Za-zА-Яа-яЁё]', 'Укажите полное имя').required('Обязательное'),
        email: yup.string().email('Укажите правильный email').matches('[a-zA-Z0-9_\.\+-]+@clickable.agency', 'Только "@clickable.agency"').required('Обязательное'),
        password: yup.string().typeError('Должно быть строкой').min(6, 'Минимум 6 символа').required('Обязательное'),
        confirmPassword: yup.string().oneOf([yup.ref('password')], 'Пароли не совпадают').min(6, 'Минимум 6 символа').required('Обязательное')
    });


    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                password: '',
                confirmPassword: ''
            }}
            validateOnBlur
            validateOnChange
            onSubmit={(values) => {console.log(values)}}
            validationSchema={validationsSchema}
        >
            {({ values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty }) => (
                <form onSubmit={handleSubmit} className="form" id="form">
                    <Input
                        name={'name'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        placeholder='Фамилия и имя'
                        errorMessage={errors.name}
                        isInvalid={touched.name && errors.name}
                    />
                    <Input
                        type={'email'}
                        name={'email'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder='Почта @clickable.agency'
                        errorMessage={errors.email}
                        isInvalid={touched.email && errors.email}
                    />
                    <Input
                        type={'password'}
                        name={'password'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder='Пароль'
                        errorMessage={errors.password}
                        isInvalid={touched.password && errors.password}
                    />
                    <Input
                        type={'password'}
                        name={'confirmPassword'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        placeholder='Еще разок пароль'
                        errorMessage={errors.confirmPassword}
                        isInvalid={touched.confirmPassword && errors.confirmPassword}
                    />


                    <ButtonForm
                        disabled={!(isValid && dirty)}
                        text='РЕГИСТРАЦИЯ'
                        click={() =>props.auth('registration', values.email,values.password,false, values.name)}
                    />
                </form>
            )}
        </Formik>
    )
}

function mapDispatchToProps(dispatch){
    return {
        auth: (fetchType, email, password,checked = false, displayName ) => dispatch(auth(fetchType, email, password, checked, displayName))
    }
}


export default connect(null, mapDispatchToProps)(Registration)