import {RERENDER, SET_END_DATE, SET_FILTER_NAME, SET_START_DATE} from "../actions/actionTypes";




const offset = (new Date().getTimezoneOffset() / 60) * -1;
const d = new Date();
const tmpDate = new Date(d.getTime()+offset);
const y = tmpDate.getFullYear();
const m = tmpDate.getMonth();
const firstDay = new Date(y, m, 1);
const endDate = new Date(y, m + 1, 0);


const initialState = {
    startDate: firstDay,
    endDate: endDate,
    filterName: 'all',
    reRenderTable: 0
};
export default function adminPanelReducer(state = initialState, action) {
    switch (action.type) {
        case SET_START_DATE:
            return {
                ...state,
                startDate: action.startDate,
            };
        case SET_END_DATE:
            return {
                ...state,
                endDate: action.endDate,
            };
        case SET_FILTER_NAME:
            return {
                ...state,
                filterName: action.filterName,
            };
        case RERENDER:
            return {
                ...state,
                reRenderTable: state.reRenderTable + 1,
            };
        default:
            return state;
    }
}
