import React, {Component} from 'react';
import {connect} from "react-redux";
import TableItemAdmin from "../TableItemAdmin/TableItemAdmin";
import {autoGetTable} from "../../store/actions/checker";
import moment from 'moment';

class TableAdmin extends Component{

    state = {
        posts: [],
        workedAll: 0,
        empty: true,
    };


    plusWorkedAll = (time) =>{
      this.setState( (state) => {
          return {workedAll: state.workedAll + time}
      });
    };

    getFilteredPosts = (email, start,end) => {
        this.props.autoGetTable(email, start, end)
            .then(res =>{
                if (res) {
                    let list = [];
                    res.forEach(doc => {
                        list = [...list, doc.data()];
                    });
                    // console.log(list.length);
                    if (list.length > 0) {
                        this.setState({
                            posts: list.sort(function (a, b) {
                                return b.date - a.date;
                            }),
                            empty: false
                        })
                    }
                }
            })
            .catch(
                null
            );
    };




    componentDidMount(){
        this.getFilteredPosts(this.props.email,this.props.startDate, this.props.endDate);

    }
    componentDidUpdate(prevProps, prevState){
        if ((prevProps.startDate !== this.props.startDate) || (prevProps.endDate !== this.props.endDate) || (prevProps.reRenderTable !== this.props.reRenderTable)) {
            this.setState({
                posts: [],
                workedAll: 0,
            })
            this.getFilteredPosts(this.props.email,this.props.startDate, this.props.endDate);
        }

    }




    render() {
        // console.log(this.state.posts.length)
        if (this.state.posts.length === 0) {
            return null
        }

        const workedAllForRender = moment.duration(this.state.workedAll, 'milliseconds').format("hh:mm", {trim: false});
        const needAllTime = this.state.posts.length * 9*60*60000;
        const leftToWork = (needAllTime - this.state.workedAll);
        const leftToWorkForRender = moment.duration(Math.abs(leftToWork), 'milliseconds').format("hh:mm", {trim: false});



        let text, className, emptyTable = null;
        // if (this.state.workedAll === 0 ) {
        //     emptyTable = (
        //         <div className="table__row form_table">
        //             <div className="table__cell table__cell--name"><span>{this.props.name}</span></div>
        //             <div className="table__cell table__cell--date"></div>
        //             <div className="table__cell table__cell--come">
        //             </div>
        //             <div className="table__cell table__cell--out">
        //             </div>
        //             <div className="table__cell table__cell--launch"></div>
        //             <div className="table__cell table__cell--all"><span>00:00</span></div>
        //             <div className="table__cell table__cell--result"><span>00:00</span></div>
        //         </div>
        //     )
        // }


        if (leftToWork > 0) {
            className = 'bg--red';
            text = 'Всего недоработано:';
        } else if (leftToWork < 0) {
            className = 'bg--green';
            text = 'Всего переработано:';
        }  else {
            className = ''; 
            text = 'Всего переработано:';
        }





        return (

            <div  className="table table--admin">

                <div className="table__table">
                    <div className="table__row table__row--header">
                        <div className="table__cell table__cell--absolute table__cell--edit"></div>
                        <div className="table__cell table__cell--name"><span>ИМЯ</span></div>
                        <div className="table__cell table__cell--date"><span>ДАТА</span></div>
                        <div className="table__cell table__cell--come"><span>НАЧАЛ</span></div>
                        <div className="table__cell table__cell--out"><span>ЗАКОНЧИЛ</span></div>
                        <div className="table__cell table__cell--launch"><span>Работал в обед</span></div>
                        <div className="table__cell table__cell--all"><span>ВСЕГО</span></div>
                        <div className="table__cell table__cell--result"><span>РЕЗУЛЬТАТ</span></div>
                    </div>

                    {
                        this.state.posts.map((item) => {
                            const {key, startTime, endTime, inDinner,date,popupLate, popupLeave,fromHome,resume} = item;
                            return (
                                <TableItemAdmin
                                    key={key}
                                    id={key}
                                    name={this.props.name}
                                    date={date}
                                    startTime={startTime}
                                    endTime={endTime}
                                    fromHome={fromHome}
                                    inDinner={inDinner}
                                    popupLate={popupLate}
                                    popupLeave={popupLeave}
                                    plusWorkedAll={this.plusWorkedAll}
                                    email={this.props.email}
                                    resume={resume}
                                />
                            )
                        })
                    }
                    {/*{emptyTable}*/}



                    <div className="table__row table__row--footer">
                        <div className="table__cell table__cell--day"><span>Рабочих дней: <span
                            className="color--white">{this.state.posts.length}</span></span></div>
                        <div className="table__cell table__cell--time"><span>Всего работал: <span
                            className="color--white">{workedAllForRender}</span></span></div>
                        <div className={"table__cell table__cell--sum color--white " + className}><span>{text} <span>{leftToWorkForRender}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}


function mapStateToProps(state) {
    return {
        startDate: state.adminPanel.startDate,
        endDate: state.adminPanel.endDate,
        reRenderTable: state.adminPanel.reRenderTable,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        autoGetTable: (email, dateStart, dateFinish) => dispatch(autoGetTable(email, dateStart, dateFinish)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TableAdmin);