import React, {useContext} from 'react';
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {ButtonContext} from "../../context/ButtonContext";
import Switch from "./switch/Switch";

const Header = (props) => {
    const buttonAnim = useContext(ButtonContext);

    let header;
    if (props.isAuthenticated && props.isAdmin) {
        header = (
            <div className="header__left">
                <Switch/>
                <Link to={"/admin"} className="header__admin">
                    АДМИН ПАНЕЛЬ
                </Link>
                <div className="header__login">
                    <Link className="header__login_name" to={"/"} >
                        {props.name}
                    </Link>

                    <Link to={"/logout"} className="header__login_img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.67 29">
                                <path className="cls-1" d="M27.3,20.17a14,14,0,1,1-.1-11.55"/>
                                <line className="cls-2" x1="14.67" y1="14.17" x2="35.67" y2="14.17"/>
                            </svg>
                    </Link>
                </div>
            </div>
        )
    }else if (props.isAuthenticated) {
        header = (
            <div className="header__left">
                <Switch/>
                <div className="header__login">
                    <Link to={"/"} style={{pointerEvents: 'none'}} className="header__login_name">
                        {props.name}
                    </Link>
                    <Link to={'/logout'}  className="header__login_img">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.67 29">
                            <path className="cls-1" d="M27.3,20.17a14,14,0,1,1-.1-11.55"/>
                            <line className="cls-2" x1="14.67" y1="14.17" x2="35.67" y2="14.17"/>
                        </svg>
                    </Link>
                </div>
            </div>
        )
    } else {
        header = (
            <div className="header__buttons">

                <Link onMouseEnter={(e)=> buttonAnim(e)} className="button button--white button--enter button--active" to={"/login"}>
                    <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg">
                        <polygon className="cls-1"
                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                        <polygon className="cls-2"
                                 points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                    </svg>
                    <span className="button__text">ВХОД</span>
                </Link>
                <Link
                    onMouseEnter={(e)=> buttonAnim(e)}
                    className="button button--clear button--reg"
                    to={"/registration"}>
                    <span className="button__text">РЕГИСТРАЦИЯ</span>
                </Link>
            </div>
        );
    }


    return (

        <div className="header">


            <Link className="header__logo" to={"/"}>
                <svg viewBox="0 0 2241.79 319.62" xmlns="http://www.w3.org/2000/svg">
                    <path className="cls-logo"
                          d="M202.5-460.4h51.27l15.82-89.73-53.08.44v.05c-109.66.56-181.18,53.5-199.84,159.32C-2.11-283.92,51.29-230.5,162-230.5h51.28l15.82-89.74H177.79c-53.42,0-76.69-15.81-67.12-70.08S149.08-460.4,202.5-460.4Z"
                          transform="translate(-12.96 550.13)"/>
                    <polygon className="cls-logo"
                             points="352.3 225.61 391.25 4.7 301.1 4.7 246.4 315.35 287.42 315.35 287.34 315.35 449.71 315.35 465.53 225.61 352.3 225.61"/>
                    <polygon className="cls-logo"
                             points="480.13 314.92 570.29 314.92 624.99 4.7 534.83 4.7 480.13 314.92"/>
                    <path className="cls-logo"
                          d="M833.19-460.4h51.27l15.83-89.73-51.36.43h0c-110.66,0-182.82,53-201.58,159.39S682-230.5,792.66-230.5h51.26l15.83-89.74H808.48c-53.42,0-76.69-15.81-67.13-70.07S779.77-460.4,833.19-460.4Z"
                          transform="translate(-12.96 550.13)"/>
                    <path className="cls-logo"
                          d="M1764.1-454.41c10.69-60.67-35.67-91-125.39-91H1531.45l-54.7,310.21H1593c89.49-.1,146.3-30,156.83-89.72,5.22-29.56-3.51-51.81-24.48-66.76C1746.24-406.59,1759.34-427.44,1764.1-454.41Zm-134.91-12.82c33.76,0,44.89,4.71,41.87,21.79-2.93,16.67-15.72,21.37-49.48,21.37h-21.36l7.61-43.16ZM1610.54-313h-29.91l7.61-43.16h29.91c33.76,0,44.89,4.7,41.95,21.36C1657.08-317.68,1644.3-313,1610.54-313Z"
                          transform="translate(-12.96 550.13)"/>
                    <path className="cls-logo"
                          d="M1033.2-545.42H943l-54.7,310.21H978.5l20.87-118.36H1022l51,118.36h392l-51.27-310.21H1302.56L1155.8-262.06l-57.25-133,123.51-150.4h-94.42L1035.34-433h-21.95M1274-313.94l66.9-133.8,19.72,133.8Z"
                          transform="translate(-12.96 550.13)"/>
                    <polygon className="cls-logo"
                             points="1855.93 225.19 1894.81 4.7 1804.64 4.7 1749.94 314.92 1790.97 314.92 1840.11 314.92 1953.34 314.92 1969.16 225.19 1855.93 225.19"/>
                    <polygon className="cls-logo"
                             points="2226.72 89.31 2241.79 4.65 2079.42 4.65 2079.26 4.7 2038.25 4.7 1983.55 314.92 2024.56 314.92 2073.7 314.92 2191.21 314.92 2206.28 229.46 2088.77 229.46 2094.65 196.13 2151.48 196.13 2165.04 119.22 2108.21 119.22 2113.48 89.31 2226.72 89.31"/>
                </svg>
            </Link>

            {header}
        </div>
    )
}
function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.auth.token,
        isAdmin: state.auth.isAdmin,
        name: state.auth.name
    }
}

export default connect(mapStateToProps)(Header);