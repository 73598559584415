import React from 'react';
import {Formik} from "formik";
import * as yup from 'yup';

import {Input} from '../input/Input'
import {ButtonForm} from '../buttonForm/ButtonForm'
import {connect} from "react-redux";
import {auth} from "../../store/actions/auth";

const Restore = (props) => {
    const validationSchema = yup.object().shape({
        email: yup.string().email('Укажите правильный email').required('Обязательное'),
    });


    return (
        <Formik
            initialValues={{
                email: '',

            }}
            validateOnBlur
            validateOnChange
            onSubmit={(values) => {console.log(values)}}
            validationSchema={validationSchema}
        >
            {({values, errors, touched, handleChange, handleBlur, isValid, handleSubmit, dirty}) => (
                <form onSubmit={handleSubmit} className="form" id="form">
                    <Input
                        type={'email'}
                        name={'email'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder={'Введи почту @clickable.agency'}
                        errorMessage={errors.email}
                        isInvalid={touched.email && errors.email}
                    />

                    <ButtonForm
                        disabled={!(isValid && dirty)}
                        click={() =>props.auth('restore', values.email)}
                        text='ОТПРАВИТЬ ССЫЛКУ НА ПОЧТУ'/>
                </form>
            )}

        </Formik>

    )
}


function mapDispatchToProps(dispatch){
    return {
        auth: (fetchType, email) => dispatch(auth(fetchType, email))
    }
}
export default connect(null, mapDispatchToProps)(Restore)