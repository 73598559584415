import React, { useEffect, useState} from "react";
import './search.css';
import {connect} from "react-redux";
import {handleSetFilterName} from "../../store/actions/adminPanel";



const Search = (props) =>{

    const [state, setState] = useState({
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        userInput: '',
    })

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }


    useEffect(() => {
        if (props.options.includes(state.userInput)) {
            props.handleSetFilterName(getKeyByValue(props.usersNameObj, state.userInput));
        } else {
            props.handleSetFilterName('all');
        }
    }, [state.userInput])





    const onChange = (e) => {


        const userInput = e.currentTarget.value;

        const filteredOptions = props.options.filter(
            (optionName) =>
                optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setState({
            ...state,
            activeOption: 0,
            filteredOptions,
            showOptions: true,
            userInput: e.currentTarget.value
        });
    };

    const onClick = (e) => {
        setState({
            ...state,
            activeOption: 0,
            filteredOptions: [],
            showOptions: false,
            userInput: e.currentTarget.querySelector('span').innerText

        });
    };
    const onKeyDown = (e) => {
        const { activeOption, filteredOptions } = state;

        if (e.keyCode === 13) {
            setState({
                ...state,
                activeOption: 0,
                showOptions: false,
                userInput: filteredOptions[activeOption]
            });
        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return;
            }
            setState({ ...state, activeOption: activeOption - 1 });
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {

                return;
            }
            setState({ ...state, activeOption: activeOption + 1 });
        }

    };




    const { activeOption, filteredOptions, showOptions, userInput } = state;
    let optionList;
    if (showOptions && userInput) {
        if (filteredOptions.length) {
            optionList = (
                <ul className="options">
                    {filteredOptions.map((optionName, index) => {
                        let className;
                        if (index === activeOption) {
                            className = 'option-active';
                        }
                        return (
                            <li className={className} key={optionName} onClick={onClick}>
                                    <span>
                                        {optionName}
                                    </span>
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            optionList = (
                <div className="no-options">
                    <em>Incorrect name!</em>
                </div>
            );
        }
    }


    return (
        <div className="tabs__search" >
            <div className="form_search" onClick={()=> document.querySelector('.form_search').classList.add('active')}>
                <div className="form_search__box form_search__box--s">
                    <input
                        type="search"
                        className="search-box"
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={userInput}
                    />
                </div>
                <div className="form_search__box form_search__box--submit">
                    <button type="button">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.31 20.31">
                        <path className="cls-1"
                              d="M16,14.62l4.28,4.28L18.9,20.31,14.62,16A9,9,0,1,1,16,14.62Zm-2-.74a7,7,0,1,0-.15.15Z"/>
                    </svg>
                </span>
                    </button>
                </div>
            </div>
            {optionList}
        </div>
    )


}
function mapDispatchToProps(dispatch) {
    return {
        handleSetFilterName: (filterName) => dispatch(handleSetFilterName(filterName)),
    }
}
export default connect(null, mapDispatchToProps)(Search);