import React, {useContext, useState} from 'react';
import SelectUsers from "../selectUsers/SelectUsers";
import {exportDBBig, setUserStartEndDate} from "../../store/actions/adminPanel";
import {connect} from "react-redux";
import {ButtonContext} from "../../context/ButtonContext";


const TabShow = (props) => {
    const [userStartEnd, setUserStartEnd] = useState({
        startDate: props.startDate,
        endDate: props.endDate,
        filterName: props.filterName,
    });
    const buttonAnim = useContext(ButtonContext);



    return (
        <div className="form_admin form_date_range">
            <div className="form_admin__block">
                <div className="form_admin__left">
                    <div className="form_admin__box">
                        <SelectUsers  all={true}/>
                    </div>
                    <div className="form_admin__box">
                        <input onChange={(e) => setUserStartEnd({...userStartEnd, startDate: new Date(e.target.value) })}  type="date" data-name="date"
                               data-valid="false"/>
                        <div className="form_admin__date">
                            <span>{userStartEnd.startDate.toLocaleDateString()}</span>
                        </div>
                    </div>
                    <div className="form_admin__box">
                        <input onChange={(e) => setUserStartEnd({...userStartEnd, endDate: new Date(e.target.value)})} type="date"  data-name="date"
                               data-valid="false"/>
                        <div className="form_admin__date">
                            <span>{userStartEnd.endDate.toLocaleDateString()}</span>
                        </div>
                    </div>
                </div>
                <div className="form_admin__right">
                    <div className="file">
                        <button

                            onClick={() => props.exportDBBig(props.filterName, userStartEnd.startDate, userStartEnd.endDate)} type='button' className="file__download">
                            EXCEL
                        </button>

                    </div>
                </div>
            </div>

            <div className="form_admin__block">
                <div className="form_admin__box form_admin__box--submit">

                    <button
                        type="button"
                        onClick={() => props.setUserStartEndDate(userStartEnd.startDate, userStartEnd.endDate, props.filterName)}
                        className="button button--red button--red_bg"
                        onMouseEnter={(e)=> buttonAnim(e)}
                    >
                        <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg"
                             preserveAspectRatio="none">
                            <polygon className="cls-1"
                                     points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                            <polygon className="cls-2"
                                     points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                        </svg>
                        <span className="button__line button__line--s"></span>
                        <span className="button__text">ПОКАЗАТЬ</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        startDate: state.adminPanel.startDate,
        endDate: state.adminPanel.endDate,
        filterName: state.adminPanel.filterName,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUserStartEndDate: (start,end,filterName) => dispatch(setUserStartEndDate(start,end,filterName)),
        exportDBBig: (email, dateStart, dateFinish) => dispatch(exportDBBig(email, dateStart, dateFinish)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabShow);
// export default Tabs;