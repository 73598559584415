import React, {useContext, useState} from 'react';
import {setPopupDispatch, setTime} from "../../store/actions/checker";
import {connect} from "react-redux";
import {ButtonContext} from "../../context/ButtonContext";

const PopupLeave = (props) => {



    const [popupLeaveText, setPopupLeaveText] = useState('');
    const [textRender, setTextRender] = useState('КУДА СОБРАЛСЯ ТАК РАНО? по времени еще работать и работать...');


    const btn = (popupLeaveText.replace(/^\s+|\s+(?=[\n\r])/gm,"").trim().length <= 5 );
    const buttonAnim = useContext(ButtonContext);
    const submitPopupLeave = (e) => {
        e.preventDefault();
        if (!btn) {
            props.setTime('popupLeave', props.email, popupLeaveText);
            sessionStorage.setItem('popup', 'done');
            localStorage.setItem('popup', 'done');
            props.setPopupDispatch('done');
            setPopupLeaveText('');
        } else {
            setTextRender('Хорошая попытка. Но все равно, придется оправдываться!')
        }

    };

    // useEffect(() => {
    //     console.log(popupLeaveText.replace(/^\s+|\s+(?=[\n\r])/gm,"").trim())
    // },[popupLeaveText])

    return (


        <div className="popups__popup leave active">
            <div className="popups__content popups__content--leave">
                <div className="title title--late">
                    РАНОВАТО ТЫ<span className="title__red">!</span>
                </div>
                <div className="title_small">
                    {textRender}
                </div>
                <form className="form" onSubmit={(e) => submitPopupLeave(e)}>
                    <div className="form__box">
                            <textarea
                                name="text"
                                placeholder="Ну, рассказывай..."
                                value={popupLeaveText}
                                onChange={(e) => setPopupLeaveText(e.target.value)}
                                ></textarea>
                        <label className="form__label--error">Ну напиши же что нибудь...</label>
                    </div>
                    <div className="form__box form__box--submit">
                        {/*{(btn) }*/}
                        <button onMouseEnter={(e)=> buttonAnim(e)} type="submit" disabled={btn} className="button button--text">
                            <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg"
                                 preserveAspectRatio="none">
                                <polygon className="cls-1"
                                         points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                                <polygon className="cls-2"
                                         points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                            </svg>
                            <span className="button__line button__line--s"></span>
                            <span className="button__text">ОТПРАВИТЬ</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>

    )
};




function mapStateToProps(state) {
    return {
        email: state.auth.email,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setTime: (item, email,value) => dispatch(setTime(item, email,value)),
        setPopupDispatch: (popup) => dispatch(setPopupDispatch(popup))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupLeave);