import React from 'react';
import {connect} from "react-redux";
import {setPopupDispatch} from "../../store/actions/checker";

const PopupDone = (props) => {

    const closePopup = () => {
        sessionStorage.removeItem('popup');
        localStorage.removeItem('popup');
        props.setPopupDispatch('');
    };



    return (
        <div className="popups__popup done active">
            <div onClick={() => {closePopup()}} className="popups__close popups__close--over"></div>
            <div className="popups__content popups__content--done">
                <div onClick={() => {closePopup()}} className="popups__close popups__close--btn"></div>
                <div className="title title--late">
                    Ну окей.
                    Подумаем мы что с тобой делать<span className="title__red">...</span>
                </div>
            </div>
        </div>
    )
};
function mapDispatchToProps(dispatch) {
    return {
        setPopupDispatch: (popup) => dispatch(setPopupDispatch(popup))
    }
}
export default connect(null, mapDispatchToProps)(PopupDone);