import {createContext} from 'react'

export const ButtonContext = createContext((e) => {
    const _self = e.currentTarget;
        if (!_self.classList.contains('button--anim')){
            _self.classList.add('button--anim');

			setTimeout(function () {
				_self.classList.remove('button--anim');
			}, 500)
		}
});
// document.querySelectorAll('.button').forEach(function (item) {
// 	item.addEventListener('mouseenter', function () {
// 		if (!this.classList.contains('button--anim')){
// 			this.classList.add('button--anim');
// 			const _self = this;
// 			setTimeout(function () {
// 				_self.classList.remove('button--anim');
// 			}, 500)
// 		}
// 	});
// });