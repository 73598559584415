import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createStore, compose, applyMiddleware} from "redux";
import {Provider} from 'react-redux';
import rootReducer from "./store/reducers/rootReducer";
import thunk from 'redux-thunk';


import firebase from 'firebase/app';
// import 'firebase/auth';
import 'firebase/firestore';



const firebaseConfig = {
    apiKey: "AIzaSyAOgvq32_IQpDMSg8NNQrB8FToUHA-tdnY",
    authDomain: "checker-clickable.firebaseapp.com",
    databaseURL: "https://checker-clickable-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "checker-clickable",
    storageBucket: "checker-clickable.appspot.com",
    messagingSenderId: "1002082971356",
    appId: "1:1002082971356:web:bc8014bfd859f88adb4e19"
};

firebase.initializeApp(firebaseConfig);



const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;


const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

ReactDOM.render(

  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)


//
// <!-- The core Firebase JS SDK is always required and must be listed first -->
// <script src="/__/firebase/8.0.0/firebase-app.js"></script>
//
// <!-- TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries -->
//
// <!-- Initialize Firebase -->
// <script src="/__/firebase/init.js"></script>