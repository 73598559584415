import React, {Component} from 'react';
import {connect} from "react-redux";
import TableItem from "../TableItem/TableItem";
import {autoGetTable} from "../../store/actions/checker";
import moment from 'moment';

class Table extends Component{
    state = {
        posts: [],
        workedAll: 0,
    };

    plusWorkedAll = (time) =>{
        this.setState( (state) => {
            return {
                ...state,
                workedAll: state.workedAll + time,
            }
        });
    };
    getFilteredPosts = (email) => {
        const offset = (new Date().getTimezoneOffset() / 60) * -1;
        const d = new Date();
        const tmpDate = new Date(d.getTime()+offset);
        const y = tmpDate.getFullYear();
        const m = tmpDate.getMonth();
        const firstDay = new Date(y, m, 1);


        this.props.autoGetTable(email, firstDay)
            .then(res =>{
                if (res) {
                    let list = [];
                    res.forEach(doc => {
                        list = [...list, doc.data()];
                    });

                    this.setState({
                        posts: list.sort(function (a, b) {
                            return b.date - a.date;
                        })
                    })
                }
            })
            .catch(
                null
            );
    };



    componentDidMount(){
        this.getFilteredPosts(this.props.email);
    }
    componentDidUpdate(prevProps){
        if ((prevProps.startTime !== this.props.startTime) || (prevProps.endTime !== this.props.endTime) || (prevProps.inDinner !== this.props.inDinner)) {
            this.getFilteredPosts(this.props.email);
        }
    }


    render() {

        const workedAll = moment.duration(Math.abs(this.state.workedAll), 'milliseconds').format("hh:mm", {trim: false});
        const needAllTime = this.state.posts.length * 9*60*60000;
        const leftToWork = (needAllTime - this.state.workedAll);

        const leftToWorkForRender = moment.duration(Math.abs(leftToWork), 'milliseconds').format("hh:mm", {trim: false});
        let text, className;

        if (leftToWork > 0) {
            className = 'bg--red';
            text = 'Всего недоработано:';
        } else if (leftToWork < 0) {
            className = 'bg--green';
            text = 'Всего переработано:';
        }  else {
            className = '';
            text = 'Всего переработано:';
        }


        return (
            <div  className="table">

                <div className="table__table">
                    <div className="table__row table__row--header">
                        <div className="table__cell table__cell--date"><span>ДАТА</span></div>
                        <div className="table__cell table__cell--come"><span>ПРИШЕЛ</span></div>
                        <div className="table__cell table__cell--out"><span>УШЕЛ</span></div>
                        <div className="table__cell table__cell--all"><span>ВСЕГО</span></div>
                        <div className="table__cell table__cell--result"><span>РЕЗУЛЬТАТ</span></div>
                    </div>

                    {

                        this.state.posts.map((item) => {
                            const {key, startTime, endTime, inDinner,date ,resume} = item;
                            return (
                                <TableItem
                                    key={key}
                                    id={key}
                                    date={date}
                                    startTime={startTime}
                                    endTime={endTime}
                                    inDinner={inDinner}
                                    plusWorkedAll={this.plusWorkedAll}
                                    resume={resume}
                                />
                            )
                        })
                    }


                    <div className="table__row table__row--footer">
                        <div className="table__cell table__cell--day"><span>Рабочих дней: <span
                            className="color--white">{this.state.posts.length}</span></span></div>
                        <div className="table__cell table__cell--time"><span>Всего работал: <span
                            className="color--white">{workedAll}</span></span></div>
                        <div className={"table__cell table__cell--sum color--white " + className}><span>{text} <span>{leftToWorkForRender}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }





}


function mapStateToProps(state) {
    return {
        email: state.auth.email,
        startTime: state.checker.startTime,
        endTime: state.checker.endTime,
        inDinner: state.checker.inDinner,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        autoGetTable: (email, dateStart, dateFinish) => dispatch(autoGetTable(email, dateStart, dateFinish)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Table);