import {RERENDER, SET_END_DATE, SET_FILTER_NAME, SET_START_DATE} from "./actionTypes";
import firebase from 'firebase/app';

import XLSX from 'xlsx';
import moment from 'moment';
import duration from 'moment-duration-format';



export function getAllUsers(){
    return async dispatch => {
        const db = firebase.firestore();
        const users = db.collection('Users');
        const snapshot = await users.get();
        return snapshot;
    }
}



export function setUserStartEndDate(start,end,filterName) {
    return dispatch => {
        dispatch(setStartDate(start));
        dispatch(setEndDate(end));
        dispatch(setFilterName(filterName))
    }
}
export function addWorkingTime(email,date,timeStart,timeEnd, fromHome = false, displatchRere = true) {
    return async dispatch => {
        const datestr = date.toLocaleDateString("az-Cyrl-AZ");
        const todayForKey = date.toLocaleDateString('fr-CA').replaceAll('-', ' - ');
        const db = firebase.firestore();
        const collectionToday = db.collection('Users').doc(email).collection('Checker');
        const docToday = collectionToday.doc(datestr);
        


        try {
            await docToday.update({
                startTime: timeStart,
                endTime: timeEnd,
                date: date,
                key: todayForKey,
                fromHome: fromHome,
            });
        } catch {
            await docToday.set({
                startTime: timeStart,
                endTime: timeEnd,
                date: date,
                key: todayForKey,
                fromHome: fromHome,
            });
        }


        if (displatchRere) {
            dispatch(rerender())
        }

    }
}
export function addTimeInDinner(email,date,inDinner) {
    return async dispatch => {
        const datestr = date.toLocaleDateString();
        const db = firebase.firestore();
        const collectionToday = db.collection('Users').doc(email).collection('Checker');
        const docToday = collectionToday.doc(datestr);


        try {
            await docToday.update({
                inDinner: Number(inDinner),
            });
        } catch {

        }
        dispatch(rerender())
    }
}





export function exportDBBig(email, dateStart, dateFinish) {
    return async dispatch => {
        const db = firebase.firestore();
        const getDate2 = (string) => new Date(0, 0, 0 , string.split(':')[0], string.split(':')[1]);

        const dateStr = new Date(new Date(dateStart).setDate(new Date(dateStart).getDate()));
        const dateEnd = new Date(new Date(dateFinish).setDate(new Date(dateFinish).getDate() + 1));

        let listAllUsers = [];

        let users = [["Имя", "Дата" , "Начало", "Закончил", "Работал в обед", "Всего", "+/-", "Удалённая работа"]];

        // const getAllItems = () => {
        //
        // }



        const collectionAllUsers = db.collection('Users');

        let collectionEmail;
        let getName;
        let name;




        if (email ==='all') {
           const getAllUsers = await collectionAllUsers.get();
            getAllUsers.forEach(doc => {
                listAllUsers.push(doc.data()["email"])
            });
        } else {
            listAllUsers.push(email)
        }



        for (const item of listAllUsers) {
            const customList = [];
            collectionEmail = collectionAllUsers.doc(item);
            const collectionToday = collectionEmail.collection('Checker');
            const getDocToday = await collectionToday.where( 'date', '>', dateStr).where( 'date', '<', dateEnd).get();


            getName = await collectionEmail.get();
            name = getName.data()['name'];
            getDocToday.forEach(doc => {
                const myItem = {...doc.data(), name};
                customList.push(myItem)
            });


            let different, timeToEnd, all,result , allTimeInDinner,customPause;
            let workedAllTime = 0;


            customList.forEach((user) => {
                if ((user.endTime !== '--:--') && user.endTime) {
                    let customDinner;
                    (user.inDinner) ? customDinner = user.inDinner : customDinner = 0;
                    const customPause = (user.resume) ?   moment.duration(user.resume, "milliseconds") : 0;
                    different = (getDate2(user.endTime) - getDate2(user.startTime) + (customDinner * 60000) - customPause);
                    timeToEnd = (9*60*60000) - different;
                    workedAllTime = workedAllTime + different;

                    all = moment.duration(different, 'milliseconds').format("hh:mm", {trim: false});
                    result = moment.duration(Math.abs(timeToEnd), 'milliseconds').format("hh:mm", {trim: false});
                    allTimeInDinner = moment.duration(customDinner, 'minutes').format("hh:mm", {trim: false});
                    // console.log(allTimeInDinner)
                } else {
                    timeToEnd = '--:--';
                    different = 0;
                    all = '--:--';
                    result = '--:--';
                    allTimeInDinner = moment.duration(user.inDinner, 'minutes').format("hh:mm", {trim: false});
                }

                const home = (user.fromHome)? 'Дом': '-';
                let text;
                if ((timeToEnd > 0) || (timeToEnd === '--:--')) {
                    text = 'Недоработал:  ';
                } else if (timeToEnd < 0) {
                    text = 'Переработал:  ';
                }  else {
                    text = 'p–p–p–perfect:  ';
                }

                let userArray = [user.name, user.key, user.startTime, user.endTime, allTimeInDinner,  all,text + result , home ];
                users.push(userArray);
            });
            const needAllTime = customList.length * 9*60*60000;
            const leftToWork = (needAllTime - workedAllTime);
            let text;

            if (leftToWork < 0) {
                text = 'Переработал: ';
            }else if (leftToWork > 0) {
                text = 'Недоработал: ';
            } else {
                text = 'Идеально: ';
            }


            const workedAllTimeForRender = moment.duration(workedAllTime, 'milliseconds').format("hh:mm", {trim: false});
            const leftToWorkForRender = moment.duration(Math.abs(leftToWork), 'milliseconds').format("hh:mm", {trim: false});

            if (customList.length !== 0) {
                users.push([null, null, null, null, null, null, `Рабочих дней: ${customList.length}`, null ]);
                users.push([null, null, null, null, null, null, `Всего работал: ${workedAllTimeForRender}`, null ]);
                users.push([null, null, null, null, null, null, `${text} ${leftToWorkForRender}`, null ]);
                users.push([]);
            }

        }



        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(users);

        const wscols = [
            {wch:25},
            {wch:20},
            {wch:13},
            {wch:13},
            {wch:18},
            {wch:13},
            {wch:25},
            {wch:20}
        ];
        ws['!cols'] = wscols;
        ws['!rows'] = [{hpt: 20}];




        XLSX.utils.book_append_sheet(wb, ws, 'Names');

        const fileName = "" + dateStart.toLocaleDateString('fr-CA') + "_" + dateFinish.toLocaleDateString('fr-CA') + ".xlsx";
        XLSX.writeFile(wb, fileName, {cellStyles:true});


    }
}



export function handleSetFilterName(filterName) {
    return async dispatch => {
        dispatch(setFilterName(filterName))
    }
}


export function setStartDate(startDate) {
    return {
        type: SET_START_DATE,
        startDate
    }
}
export function setEndDate(endDate) {
    return {
        type: SET_END_DATE,
        endDate
    }
}
export function setFilterName(filterName) {
    return {
        type: SET_FILTER_NAME,
        filterName
    }
}
export function rerender() {
    return {
        type: RERENDER
    }
}