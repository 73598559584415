import {SET_END_TIME, SET_IN_DINNER, SET_START_TIME, SET_POPUP} from "./actionTypes";
import firebase from 'firebase/app';
import 'firebase/database';
import moment from "moment";

export function setTime(item,email, value=null){
    return async dispatch => {
        const db = firebase.firestore();
        let dbForNow = new Date(firebase.firestore.Timestamp.now().seconds * 1000);
        await firebase.database().ref('/.info/serverTimeOffset')
            .once('value')
            .then(function  stv(data) {
                dbForNow =  new Date(data.val() + Date.now())
            })
            .catch(err => console.log(err))





        const timeNow = dbForNow.toLocaleTimeString(['en-GB'], {hour: '2-digit', minute:'2-digit', timeZone: 'Europe/Kiev'});




        const today = new Date().toLocaleDateString("az-Cyrl-AZ");
        const todayForKey = moment(dbForNow).format("YYYY-MM-DD");

        console.log(todayForKey)


        const collectionToday = db.collection('Users').doc(email).collection('Checker');
        const docToday = collectionToday.doc(today);
        switch (item){
            case 'startTime':
                await docToday.set({
                    startTime: timeNow,
                    endTime: '--:--',
                    inDinner: 0,
                    date: firebase.firestore.Timestamp.fromDate(new Date()),
                    key: todayForKey.replaceAll('-', ' - '),
                    fromHome: false
                });

                dispatch(setStartTime(timeNow)) ;
                if (timeNow > '11:00') {
                    dispatch(setPopupDispatch('late'));
                    localStorage.setItem('popup', 'late');
                }

                break;
            case 'endTime':
                await docToday.update({
                    endTime: timeNow,
                });
                dispatch(setEndTime(timeNow));
                if (timeNow < '18:00') {
                    dispatch(setPopupDispatch('leave'));
                    localStorage.setItem('popup', 'leave');
                }
                break;
            case 'inDinner':
                await docToday.update({
                    inDinner: Number(value),
                });
                dispatch(setInDinner(Number(value)));
                break;
            case 'resume':
                let listResume


                await docToday.get().then((data) => {
                    const finish = moment.duration(data.data().endTime)
                    const resumeTime = moment.duration(timeNow)
                    const subsc = resumeTime.subtract(finish).format('hh:mm', {trim: false})

                    const getResume = data.data().resume || []

                    listResume = moment.duration(getResume).add( moment.duration(subsc) ).format('hh:mm', {trim: false})
                    console.log(listResume);
                });
                await docToday.update({
                    resume: listResume,
                });



                await docToday.update({
                    endTime: '--:--',
                });
                dispatch(setEndTime('--:--'));
                break;
            case 'popupLeave':
                await docToday.update({
                    popupLeave: value,
                });
                break;
            case 'popupLate':
                await docToday.update({
                    popupLate: value,
                });
                break;
            default:
                break;
        }

    }

}


export function autoGetTime(email) {
    return async dispatch => {
        const today = moment(new Date()).format("DD.MM.YYYY");

        // console.log()
        const db = firebase.firestore();
        const collectionToday = db.collection('Users').doc(email).collection('Checker');
        const docToday = collectionToday.doc(today);
        const getDocToday = await docToday.get();


        if (getDocToday.exists) {
            if (!!getDocToday.data().startTime) {
                dispatch(setStartTime(getDocToday.data().startTime)) ;
            }
            if(!!getDocToday.data().endTime){
                dispatch(setEndTime(getDocToday.data().endTime));
            }
            if(!!getDocToday.data().inDinner){
                dispatch(setInDinner(getDocToday.data().inDinner));
            }
        }
    }
}
export function autoGetTable(email, dateStart, dateFinish = new Date()) {
    return async dispatch => {
        const dateStr = new Date(new Date(dateStart).setDate(new Date(dateStart).getDate()));
        const dateEnd = new Date(new Date(dateFinish).setDate(new Date(dateFinish).getDate() + 1));
        const db = firebase.firestore();
        const checker = db.collection('Users').doc(email).collection('Checker');
        const docs = await checker.where( 'date', '>=', dateStr).where( 'date', '<', dateEnd).get();
        if(docs.empty) {
            return null;
        }
        return docs;
    }
}



export function setPopupDispatch(popup) {
    return dispatch => {
        dispatch(setPopup(popup))
    }
}



export function setStartTime(startTime){
    return {
        type: SET_START_TIME,
        startTime
    }
}
export function setEndTime(endTime) {
    return {
        type: SET_END_TIME,
        endTime
    }
}

export function setInDinner(inDinner) {
    return {
        type: SET_IN_DINNER,
        inDinner
    }
}



export function setPopup(popup) {
    return {
        type: SET_POPUP,
        popup
    }
}
