import React, {useEffect} from 'react';
import Registration from "../registration/Registration";
import {connect} from "react-redux";
import {errorMessage} from "../../store/actions/auth";


const RegistrationPage = (props) => {
    useEffect(() => {
        props.errorMessageF('')
    },[]);
    return (
        <>
            <div className="main">
                <div className="main__form">
                    <div className="title title--reg">
                        регистрациЯ
                        В Отмечалочке<span className="title__red">.</span>
                    </div>
                    <Registration/>
                    <div className='main__error-message'>{props.errorMessage}</div>
                </div>
            </div>
        </>
    )
};

function mapStateToProps(state){
    return {
        errorMessage: state.auth.errorMessage
    }
}
function mapDispatchToProps(dispatch) {
    return {
        errorMessageF: (item) => dispatch(errorMessage(item))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);