import axios from 'axios'
import firebase from 'firebase/app';
// import 'firebase/auth';        // for authentication
// import 'firebase/firestore';   // for cloud firestore

import {AUTH_LOGOUT, AUTH_SUCCESS, ERROR_MESSAGE, REDIRECT, SET_THEME} from "./actionTypes";
import {setPopupDispatch} from "./checker";


export function auth(fetchType, email,password,checked = false, displayName = null) {

    return async dispatch => {
        let authData, url;
        switch (fetchType){
            case 'login':
                authData = {
                    email,
                    password,
                    returnSecureToken: true
                };
                url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyAOgvq32_IQpDMSg8NNQrB8FToUHA-tdnY'
                break;

            case 'registration':
                authData = {
                    email,
                    password,
                    displayName,
                    returnSecureToken: true
                };
                 url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAOgvq32_IQpDMSg8NNQrB8FToUHA-tdnY'
                break;

            case 'restore':
                authData = {
                    requestType: 'PASSWORD_RESET',
                    email
                };
                url = 'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyAOgvq32_IQpDMSg8NNQrB8FToUHA-tdnY'
                break;
            default:
                break;
        }

        try {
            const response = await axios.post(url, authData);
            const data = response.data;
            console.log(data);
            console.log(response);
            const db = firebase.firestore();

            switch (fetchType){
                case 'login':
                    // Cloud Firestore
                    const user = db.collection('Users').doc(data.email);
                    const doc = await user.get();
                    console.log(doc.data());

                    // doc.data()

                    // localStorage
                    const expirationDate = new Date(new Date().getTime() + data.expiresIn * 1000);
                    if (checked){
                        localStorage.setItem('token', data.idToken);
                        localStorage.setItem('userId', data.localId);
                        localStorage.setItem('expirationDate', expirationDate);
                        localStorage.setItem('displayName', data.displayName);
                        localStorage.setItem('isAdmin', doc.data().isAdmin);
                        localStorage.setItem('theme', doc.data().theme);
                        localStorage.setItem('email', data.email);

                        if (!localStorage.getItem('popup')) {
                            localStorage.setItem('popup', '');
                        }

                        localStorage.setItem('refreshToken', data.refreshToken);
                    } else {
                        sessionStorage.setItem('token', data.idToken);
                        sessionStorage.setItem('userId', data.localId);
                        sessionStorage.setItem('expirationDate', expirationDate);
                        sessionStorage.setItem('displayName', data.displayName);
                        sessionStorage.setItem('isAdmin', doc.data().isAdmin);
                        sessionStorage.setItem('theme', doc.data().theme);
                        sessionStorage.setItem('email', data.email);
                        sessionStorage.setItem('refreshToken', data.refreshToken);
                        if (!localStorage.getItem('popup')) {
                            localStorage.setItem('popup', '');
                        }
                    }
                    dispatch(authSuccess(data.idToken, data.displayName, data.email, doc.data().isAdmin));


                    // dispatch(autoLogout(data.expiresIn));
                    console.log(doc.data().isAdmin);
                    break;

                case 'registration':
                    // localStorage
                    dispatch(redirect('/login'));

                    // Cloud Firestore
                    const users = db.collection('Users');
                    const newUser = users.doc(data.email);
                    newUser.set({
                        name: data.displayName,
                        id: data.localId,
                        email: data.email,
                        isAdmin: false,
                        theme: 'dark'
                    });
                    break;

                case 'restore':
                    sessionStorage.setItem('email', data.email);
                    dispatch(redirect('/done'));
                    break;
                default:
                    break;
            }


        } catch (e){
            switch(e.response.data.error.message){
                case ('INVALID_PASSWORD'):
                    dispatch(errorMessage('Пароль недействителен'));
                    break;
                case ('EMAIL_NOT_FOUND'):
                    dispatch(errorMessage('Нет пользовательской записи с такой почтой'));
                    break;
                case ('EMAIL_EXISTS'):
                    dispatch(errorMessage('Адрес электронной почты уже используется'));
                    break;
                    // TOO_MANY_ATTEMPTS_TRY_LATER
                case ('TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'):
                    dispatch(errorMessage('Доступ к этой учетной записи временно отключен из-за множества неудачных попыток входа. Вы можете немедленно восстановить, сбросив пароль, или можете повторить попытку позже.'));
                    break;
                default:

                    dispatch(errorMessage('Ошибка'));
            }
        }


    }
}

export function authSuccess(token, name, email, isAdmin) {
    return {
        type: AUTH_SUCCESS,
        token,
        name,
        email,
        isAdmin
    }
}
export function changeTheme(email,theme) {
    return async dispatch => {
        const db = firebase.firestore();
        const collection = db.collection('Users').doc(email)
        let name = localStorage.getItem('displayName');
        if (name) {
            localStorage.setItem('theme', theme);
        } else {
            sessionStorage.setItem('theme', theme);
        }
        dispatch(setTheme(theme))
        await collection.update({
            theme: theme
        })



    }


}
export function autoLogin() {
    return dispatch => {
        // const token = sessionStorage.getItem('token');
        // const name = sessionStorage.getItem('displayName');
        let token = localStorage.getItem('token');
        let name = localStorage.getItem('displayName');
        let email = localStorage.getItem('email');
        let isAdmin = localStorage.getItem('isAdmin');
        let theme = localStorage.getItem('theme');
        let popup = localStorage.getItem('popup');
        let refreshToken = localStorage.getItem('refreshToken');

        if (!token && sessionStorage.getItem('token')){
            token = sessionStorage.getItem('token');
            name = sessionStorage.getItem('displayName');
            email = sessionStorage.getItem('email');
            isAdmin = sessionStorage.getItem('isAdmin');
            theme = sessionStorage.getItem('theme');
            refreshToken = sessionStorage.getItem('refreshToken');
        }



        if (!token) {
            dispatch(logout())
        } else {
            let expirationDate;
           if (localStorage.getItem('expirationDate')) {
               expirationDate = new Date(localStorage.getItem('expirationDate'));
           } else {
               expirationDate = new Date(sessionStorage.getItem('expirationDate'));
           }


            if (expirationDate <= new Date()){
                // dispatch(logout())
                const url = 'https://securetoken.googleapis.com/v1/token?key=AIzaSyAOgvq32_IQpDMSg8NNQrB8FToUHA-tdnY';
                const data = `grant_type=refresh_token&refresh_token=${refreshToken}`;
                const header = 'application/x-www-form-urlencoded';
                axios.post(url,  data,{
                    headers: { 'content-type': header },
                }).then(
                    function(response) {
                        if (response.status==200) {
                            const data = response.data
                            const expirationDate = new Date(new Date().getTime() + data.expires_in * 1000);

                            const userId = localStorage.getItem('userId');

                            if (!!userId){
                                localStorage.setItem('token', data.id_token);
                                localStorage.setItem('expirationDate', expirationDate);
                                localStorage.setItem('refreshToken', data.refresh_token);
                            } else if (!userId && !!sessionStorage.getItem('userId')) {
                                sessionStorage.setItem('token', data.id_token);
                                sessionStorage.setItem('expirationDate', expirationDate);
                                sessionStorage.setItem('refreshToken', data.refresh_token);
                            }
                        }
                    }
                ).catch(
                    function (error) {
                        if (error.response.status==404) {

                        }
                    }
                );
            }

            dispatch(authSuccess(token, name, email, isAdmin));
            dispatch(setPopupDispatch(popup));
            dispatch(setTheme(theme))



        }
    }
}
export function errorMessage(errorMessage) {
    return {
        type: ERROR_MESSAGE,
        errorMessage
    }
}

export function autoLogout(time) {
    return dispatch => {
        setTimeout(() => {
            // dispatch(logout())
        }, time * 1000)
    }
}
export function setTheme(theme){
    return {
        type: SET_THEME,
        theme
    }
}
export function logout() {
    // return null;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('expirationDate');
    sessionStorage.removeItem('displayName');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('theme');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('refreshToken');

    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('displayName');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('theme');
    localStorage.removeItem('email');
    // localStorage.removeItem('popup');
    localStorage.removeItem('refreshToken');
    return  {
        type: AUTH_LOGOUT
    }
}

export function redirect(redirect) {
    return {
        type: REDIRECT,
        redirect
    }
}