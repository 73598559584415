import React, {Component} from "react";
import {connect} from "react-redux";
import {logout, setTheme} from "../../store/actions/auth";
import {Redirect} from "react-router-dom";
import {setEndTime, setInDinner, setPopup, setStartTime} from "../../store/actions/checker";

class Logout extends Component {
    componentDidMount() {
        let name = localStorage.getItem('displayName');
        this.props.setTheme('dark')
        if (name) {
            localStorage.setItem('theme', 'dark');
        } else {
            sessionStorage.setItem('theme', 'dark');
        }


        this.props.logout()
        this.props.setStartTime('--:--')
        this.props.setEndTime('--:--')
        this.props.setInDinner(0)
        this.props.setPopup('')

    }

    render(){
        return <Redirect to={'/login'}/>
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout()),
        setStartTime: (startTime) => dispatch(setStartTime(startTime)),
        setEndTime: (endTime) => dispatch(setEndTime(endTime)),
        setInDinner: (inDinner) => dispatch(setInDinner(inDinner)),
        setPopup: (popup) => dispatch(setPopup(popup)),
        setTheme: (theme) => dispatch(setTheme(theme))
    }
}

export default connect(null, mapDispatchToProps)(Logout)