// Auth
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const REDIRECT = 'REDIRECT';


// checker
export const SET_START_TIME = 'SET_START_TIME';
export const SET_END_TIME = 'SET_END_TIME';
export const SET_IN_DINNER = 'SET_IN_DINNER';
export const SET_POPUP = 'SET_POPUP';
export const SET_THEME = 'SET_THEME';

// AdminPanel
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_FILTER_NAME = 'SET_FILTER_NAME';
export const RERENDER = 'RERENDER';