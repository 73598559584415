import React from 'react';
import {Link} from "react-router-dom";

const DonePage = () => {
    return (
        <>
            <div className="main">

                <div className="main__form">
                    <div className="title title--restore">
                        ССЫЛКА ДЛЯ
                        ВОССТАНОВЛЕНИЯ
                        ПАРОЛЯ ОТПРАВЛЕНА
                        НА ПОЧТУ<span className="title__red">.</span>
                    </div>
                    <div className="title_small">
                        Заходи на {sessionStorage.getItem('email')} почту и следуй инструкции
                    </div>
                    <div className="main__form_href main__form_href--left">
                        <Link to={"/login"} className="main__form_text">Вернуться</Link>
                    </div>
                </div>
            </div>
        </>
    )
};

export default DonePage;