import React, {useEffect, useState} from 'react';
import Tabs from '../Tabs/Tabs'
import {getAllUsers} from "../../store/actions/adminPanel";
import {connect} from "react-redux";
import {RateContext} from "../../context/RateContext";
import TableAdmin from "../tableAdmin/TableAdmin";




const AdminPage = (props) => {
    let initialAllUsers = props.getAllUsers()
        .then(res =>{
            if (res) {
                const list = [];
                res.forEach(doc => {
                    const item = doc.data();
                    item.key = doc.data().id;
                    list.push(item);
                });

                return list;
            }
        })
        .catch(
            null
        );


    const [allAdminTables, setAllAdminTables] = useState([]);
    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        Promise.resolve(initialAllUsers).then(function(value) {
            setAllAdminTables(value);
        });
    }, []);




    const funcSetFilterd = (idUser = 'all') => {
        if (idUser === 'all') {
            setFiltered(allAdminTables
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => <TableAdmin name={item.name} key={item.id} email={item.email}/>));
        } else {
            setFiltered(allAdminTables
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter((item) => item.email === idUser)
                .map((item) => <TableAdmin name={item.name} key={item.id} email={item.email}/>));
        }
    };
    useEffect(() => {
        funcSetFilterd();
    },[allAdminTables]);


    useEffect(() => {
        funcSetFilterd(props.filterName);
    }, [props.filterName]);


    useEffect(() => {
        setFiltered([]);
        setTimeout(() => {
            funcSetFilterd(props.filterName);
        },1)

    }, [props.reRenderTable]);




    return (
        <RateContext.Provider
            value={
                allAdminTables
            }
        >
            <div className="main">
                <div className="main__block">
                    <div className="title title--restore">
                        АДМИН ПАНЕЛЬ<span className="title__red">.</span>
                    </div>
                    <Tabs />
                    {filtered}
                </div>
            </div>
        </RateContext.Provider>
    )
};


function mapStateToProps(state) {
    return {
        filterName: state.adminPanel.filterName,
        reRenderTable: state.adminPanel.reRenderTable,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getAllUsers: () => dispatch(getAllUsers()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);