import React, {useContext} from 'react';
import {ButtonContext} from "../../context/ButtonContext";

export const ButtonForm = (props) => {
    const buttonAnim = useContext(ButtonContext);

    return (
        <div className="form__box form__box--submit">
            <button
                disabled={props.disabled}
                type="submit"
                className="button"
                onClick={props.click}
                onMouseEnter={(e)=> buttonAnim(e)}
            >
                <svg viewBox="0 0 225 56" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <polygon className="cls-1"
                             points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                    <polygon className="cls-2"
                             points="215.5 43.5 203.83 55.5 0.5 55.5 0.5 0.5 224.5 0.5 224.5 34.67 215.5 43.5"></polygon>
                </svg>
                <span className="button__line button__line--s"></span>
                <span className="button__text">{props.text}</span>
            </button>
        </div>
    )
}