import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {addWorkingTime} from "../../store/actions/adminPanel";
import moment from 'moment';




const TableItemAdmin = (props) => {
    const getDate = (string) => new Date(0, 0, 0 , string.split(':')[0], string.split(':')[1]); //получение даты из строки (подставляются часы и минуты
    const [tableRowEditClass, setTableEditClass] = useState(false);
    const [data, setData] = useState({
        startTime: props.startTime,
        endTime: props.endTime,
        fromHome: props.fromHome
    });
    let different, timeToEnd, all, result, allTimeInDinner;



    const inDinner  = (props.inDinner) ? props.inDinner * 60000 : 0;
    const pause  = (props.resume) ? moment.duration(props.resume, "milliseconds") : 0;
    const differentPrev = (getDate(props.endTime) - getDate(props.startTime) + inDinner - pause);
    allTimeInDinner = moment.duration(Math.abs(inDinner), 'milliseconds').format("hh:mm", {trim: false});
    if ((data.endTime !== '--:--') && data.endTime) {
        different = (getDate(data.endTime) - getDate(data.startTime) + inDinner - pause);
        timeToEnd = (9*60*60000) - different;

        all = moment.duration(Math.abs(different), 'milliseconds').format("hh:mm", {trim: false});
        result = moment.duration(Math.abs(timeToEnd), 'milliseconds').format("hh:mm", {trim: false});

    } else if (new Date().toLocaleDateString('fr-CA') === props.id.replaceAll(' ', '')){
        timeToEnd = '--:--';
        different = 9*60*60000;
    } else {
        timeToEnd = '--:--';
        different = 0;

    }

    let text, className;
    if (timeToEnd > 0) {
        className = 'color--red';
        text = 'НедоработКА:';
    } else if (timeToEnd < 0) {
        className = 'color--green';
        text = 'ПереработКА:';
    } else if (new Date().toLocaleDateString('fr-CA') === props.id.replaceAll(' ', '')) {
        className = '';
        text = '';
    } else if (timeToEnd === '--:--') {
        className = 'color--red';
        text = 'НедоработКА: 09:00';
    }  else {
        className = '';
        text = 'p–p–p–perfect:';
    }









    const popupItem = (item, leave = false) => {
        let home = null, comment = null;
        if (leave) {
            home = (
                <svg
                    className={"home_img" + ((data.fromHome )? ' home_img--active':'')}
                    onClick={() => setData({...data, fromHome: !data.fromHome})}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 17.5 14.88">
                    <path className="cls-1" d="M7,14.88V9.63h3.5v5.25h4.38v-7H17.5L8.75,0,0,7.88H2.63v7Z"/>
                </svg>
            )
        }
        if (item) {
            comment = (
                <div className="table__comment active">
                    <div className="table__comment_img">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.5 17.5">
                            <path className="cls-1"
                                  d="M15.75,0h-14A1.76,1.76,0,0,0,0,1.75v10.5A1.76,1.76,0,0,0,1.75,14h3.5v2.63a.83.83,0,0,0,.88.88h.44a1,1,0,0,0,.61-.26L10.41,14h5.34a1.76,1.76,0,0,0,1.75-1.75V1.75A1.76,1.76,0,0,0,15.75,0ZM7.88,9.63H4.38V5.95L5.51,3.5H7.26L6,6.13H7.88Zm5.25,0H9.63V5.95L10.76,3.5h1.75L11.29,6.13h1.84Z"/>
                        </svg>
                    </div>
                    <div className="table__comment_text">
                        {item}
                    </div>
                </div>
            )
        }
        return (
            <div className="table__cell_right">
                {home}
                {comment}
            </div>
        )
    };

    useEffect(() => {
        props.plusWorkedAll(different)
    },[]);


    const sumbitNewData = () => {
        if (data.endTime !== '--:--' && data.endTime) {
            props.plusWorkedAll(getDate(data.endTime) - getDate(data.startTime) + (inDinner))
            props.plusWorkedAll(-differentPrev)


        }

        setTableEditClass(!tableRowEditClass)
        props.addWorkingTime(
            props.email,
            new Date(props.id.replaceAll(' ', '')),
            data.startTime,
            data.endTime,
            data.fromHome,
            false,
            props.id
        )

    }


    // console.log(props.id)



    return (
        <>
            <div className={"table__row form_table" + ((tableRowEditClass)? ' table__row--edit':'')}>
                <div onClick={()=> setTableEditClass(!tableRowEditClass)} className="table__cell table__cell--absolute table__cell--edit">
                    <svg className="edit_img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 16.41 16.41">
                        <path className="cls-1"
                              d="M15.75,14.85H.66A.66.66,0,0,0,0,15.5v.74a.16.16,0,0,0,.16.16H16.24a.16.16,0,0,0,.16-.16V15.5A.66.66,0,0,0,15.75,14.85ZM3,13.13h.12l3.45-.61a.2.2,0,0,0,.11-.06l8.69-8.69,0-.07a.2.2,0,0,0,0-.16l0-.07L12,.06a.21.21,0,0,0-.29,0L3,8.75a.21.21,0,0,0-.06.11l-.6,3.45a.69.69,0,0,0,.19.61A.69.69,0,0,0,3,13.13Z"/>
                    </svg>
                </div>
                <div
                    className="table__cell table__cell--absolute table__cell--send">
                    <button
                        type="button"
                        onClick={()=> sumbitNewData()}
                        disabled={(data.startTime > data.endTime && data.endTime !== '--:--')}
                    >
                        <svg className="done_img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 16.56 13.6">
                            <path className="cls-1"
                                  d="M5.43,13.6,0,8.16,2.48,5.69l3,3L14.08,0l2.48,2.48Z"/>
                        </svg>
                    </button>
                </div>
                <div className="table__cell table__cell--name"><span>{props.name}</span></div>
                <div className="table__cell table__cell--date"><span>{props.id}</span></div>
                <div className="table__cell table__cell--come">
                    <div className="table__cell_left">
                        <span className={'table__cell_time'}>{data.startTime}</span>
                        <div className="table__cell_input">
                            <input
                                type="time"
                                onChange={(e) => setData({...data,startTime: e.target.value})}
                                value={data.startTime}
                                />
                        </div>
                    </div>
                    {popupItem(props.popupLate)}


                </div>
                <div className="table__cell table__cell--out">
                    <div className="table__cell_left">
                        <span className={'table__cell_time'}>{(data.endTime !== '--:--') ? data.endTime : '--:--'}</span>
                        <div className="table__cell_input">
                            <input
                                type="time"
                                onChange={(e) => setData({...data,endTime: e.target.value})}
                                value={data.endTime} />
                        </div>
                    </div>
                    {popupItem(props.popupLeave, true)}
                </div>
                <div className="table__cell table__cell--launch"><span>{allTimeInDinner}</span></div>
                <div className={"table__cell table__cell--all " + className}>
                    <span>{all}</span>
                    {(props.resume && props.endTime !== '--:--') ?  (
                        <div className="table__cell_right table__cell_right--dinner table__cell_right--pause">


                            <div className="table__comment active">
                                <div className="table__comment_img table__comment_img--dinner">
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 2C5.81158 2 2 5.81158 2 10.5C2 15.1884 5.81158 19 10.5 19C15.1884 19 19 15.1884 19 10.5C19 5.81158 15.1884 2 10.5 2ZM9.42632 13.0411C9.42632 13.7747 8.83579 14.3653 8.10211 14.3653C7.36842 14.3653 6.7779 13.7747 6.7779 13.0411V7.95895C6.7779 7.22526 7.36842 6.63474 8.10211 6.63474C8.83579 6.63474 9.42632 7.22526 9.42632 7.95895V13.0411ZM14.2221 13.0411C14.2221 13.7747 13.6316 14.3653 12.8979 14.3653C12.1642 14.3653 11.5737 13.7747 11.5737 13.0411V7.95895C11.5737 7.22526 12.1642 6.63474 12.8979 6.63474C13.6316 6.63474 14.2221 7.22526 14.2221 7.95895V13.0411Z" fill="white"/>
                                    </svg>
                                </div>
                                <div className="table__comment_text table__comment_text--dinner">
                                    Пауза в работе: {moment.duration(props.resume).format('h\ч m\м')}
                                </div>
                            </div>
                        </div>


                    ) : null}
                </div>
                <div className={"table__cell table__cell--result " + className}><span>{text} {result}</span></div>
            </div>

        </>
    )




}

function mapDispatchToProps(dispatch) {
    return {
        addWorkingTime: (email,date,timeStart,timeEnd, fromHome,displatchRere, key) => dispatch(addWorkingTime(email,date,timeStart,timeEnd, fromHome,displatchRere, key)),
    }
}


export default connect(null, mapDispatchToProps)(TableItemAdmin);