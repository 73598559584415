import React from 'react';
import PopupLate from "./PopupLate";
import PopupLeave from "./PopupLeave";
import PopupDone from "./PopupDone";
import {connect} from "react-redux";

const Popups = (props) => {

    let popup;
    switch (props.popup){
        case 'late':
            popup = <PopupLate/>;
            break;
        case 'leave':
            popup = <PopupLeave/>;
            break;
        case 'done':
            popup = <PopupDone/>;
            break;
        default:
            return null;
    }

    return (
        <div className="popups">
            {popup}
        </div>
    )
};



function mapStateToProps(state) {
    return {
       popup: state.checker.popup,
    }
}
export default connect(mapStateToProps, null)(Popups);