import {AUTH_LOGOUT, AUTH_SUCCESS, ERROR_MESSAGE, REDIRECT, SET_THEME} from "../actions/actionTypes";

const initialState = {
    token: null,
    name: null,
    email: null,
    isAdmin: null,
    errorMessage: null,
    redirect: null,
    theme: null
};


export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_SUCCESS:
            return {
                ...state,
                token: action.token,
                name: action.name,
                email: action.email,
                isAdmin: (action.isAdmin === 'true')
            };
        case AUTH_LOGOUT:
            return {
                ...state,
                token: null,
                name: null,
                email: null,
                isAdmin: null
            };
        case ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        case REDIRECT:
            return {
                ...state,
                redirect: action.redirect,
                errorMessage: null
            };
        case SET_THEME:
            return {
                ...state,
                theme: action.theme,
            };
        default:
            return state
    }
}